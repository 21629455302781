/** @jsx jsx */
import { jsx, Heading, Link as TLink, Input } from "theme-ui";
import { Link } from "gatsby";
import { Flex } from "@theme-ui/components";
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout";
import Listing from "@lekoarts/gatsby-theme-minimal-blog/src/components//listing";
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config";
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes";
import SEO from "./seo";
import React from "react";

type PostsProps = {
  posts: {
    slug: string;
    title: string;
    date: string;
    excerpt: string;
    description: string;
    timeToRead?: number;
    tags?: {
      name: string;
      slug: string;
    }[];
  }[];
};

const Blog = ({ posts }: PostsProps) => {
  const { tagsPath, basePath } = useMinimalBlogConfig();
  const allContent = posts;

  const emptyQuery = "";
  const [state, setState] = React.useState({
    filteredData: [],
    query: emptyQuery,
  });

  const handleInputChange = (event) => {
    const query = event.target.value;

    // this is how we get all of our pages
    const pages = posts || [];
    // return all filtered pages
    const filteredData = pages.filter((page) => {
      // destructure data from page frontmatter
      const { title, tags, description } = page;

      return (
        // standardize data with .toLowerCase()
        // return true if the description, title or tags
        // contains the query string

        // description.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase()) ||
        tags
          .join("") // convert tags from an array to string
          .toLowerCase()
          .includes(query.toLowerCase())
      );
    });
    // update state according to the latest query and results
    setState({
      query, // with current query string from the `Input` event
      filteredData, // with filtered data from pages.filter(page => (//filteredData)) above
    });
  };

  const { filteredData, query } = state;
  const hasSearchResults = filteredData && query !== emptyQuery;
  const pages = hasSearchResults ? filteredData : allContent;

  console.log("PAGES: ", pages);

  return (
    <Layout>
      <SEO title="Blog" />
      <Flex sx={{ alignItems: `center`, justifyContent: `space-between`, flexFlow: `wrap` }}>
        <Heading variant="styles.h2">Articoli</Heading>

        <TLink as={Link} sx={{ variant: `links.secondary` }} to={replaceSlashes(`/${basePath}/${tagsPath}`)}>
          View all tags
        </TLink>
      </Flex>
      <Flex sx={{ alignItems: `left`, justifyContent: `center`, flexFlow: `wrap`, flexDirection: "column" }}>
        <Input
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          type="text"
          aria-label="Search"
          placeholder="Type to filter pages..."
          onChange={handleInputChange}
          sx={{
            width: ["100%", "300px", "500px"],
          }}
        />
        <p>Total {pages.length}</p>
      </Flex>
      <Listing posts={pages} sx={{ mt: [4, 5] }} />
    </Layout>
  );
};

export default Blog;
